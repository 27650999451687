<template>
  <section class="section-block-intro" id="intro">
    <div class="section-content-intro">
      <div class="identity">
        <img
          class="profil-img intro"
          src="../assets/img/profile-photo-copy-right-majed-khreim.jpg"
          alt=""
        />
        <div class="identity-text">
          <p class="before-title intro">Bonjour, Je m'appelle</p>
          <h1 class="section-title majed intro">Majed Khreim,</h1>
          <h2 class="dev intro">
            <span class="hi"></span>
            <span class="text"></span>
            <span class="cursor">_</span>
          </h2>
        </div>
      </div>
      <div class="about-level-1">
        <div class="text-about">
          <p class="text-dark-big intro parag">
            J'ai développé des compétences en développement web front-end et en web design
            grâce à différentes expériences professionnelles et éducatives.
          </p>
          <p class="text-dark-big intro parag">
            Je possède un profil polyvalent, avec une certaine expertise dans le
            développement de sites et d'applications web, en mettant particulièrement
            l'accent sur le front-end. J'ai eu l'occasion de travailler sur l'intégration,
            en prenant en compte les interactions et les animations, tout en veillant à
            suivre les principes du design responsive, de l'accessibilité, des balises
            sémantiques, de Schema.org, de la hiérarchie et de la performance. J'ai
            également acquis de l'expérience dans la conception d'interfaces web, en
            veillant à intégrer attentivement à la fois l'UX et l'UI, ainsi qu'en créant
            des wireframes, des maquettes graphiques, des prototypes et des icônes.
          </p>
          <p class="text-dark-big intro parag">
            Mon principal objectif actuel est de collaborer sur des projets numériques
            innovants.
          </p>
        </div>
      </div>
      <!-- <KinesisContainer>
        <KinesisElement> -->
      <div class="intro">
        <Btn :link="link" :btnLeft="btnLeft" class="cursor-ext"> Me contacter </Btn>
      </div>
      <!-- </KinesisElement>
      </KinesisContainer> -->
    </div>
  </section>
</template>

<script>
import Btn from "./Btn.vue";
// import { KinesisContainer, KinesisElement } from "vue-kinesis";
export default {
  name: "Intro",
  components: {
    Btn,
    // KinesisContainer,
    // KinesisElement,
  },
  data() {
    return {
      link: "https://www.linkedin.com/in/majedkhreim/",
      btnLeft: true,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../scss/variables.scss";
.section-block-intro {
  width: 100%;
  height: 100vh;
  min-height: 700px;
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10em;
  background: rgb(16, 21, 32);
  background: linear-gradient(
    360deg,
    rgba(16, 21, 32, 0.4822303922) 21%,
    #097487 852%,
    rgba(0, 227, 255, 0.4822303922) 100%
  );
}
.section-content-intro {
  width: 70%;
}
.profil-img {
  width: 120px;
  height: 120px;
  border-radius: 10%;
  -webkit-box-shadow: 2px 7px 19px -6px rgba(0, 0, 0, 0.54);
  box-shadow: 2px 7px 19px -6px rgba(0, 0, 0, 0.54);
  border: 5px solid $bkg-b;
}
.circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 2px solid $green;
}
.dev.intro {
  font-size: 1.4em;
}
.identity {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2em;
  margin-top: 2em;
}
.identity-text {
  margin-left: 1em;
}
.about-level-1 {
  margin-bottom: 2em;
}
.parag {
  // text-align: justify;
}
.text-about {
  margin-bottom: 3em;
}
.before-title {
  margin-bottom: 10px;
  font-weight: $light;
  font-size: $small;
  color: $green;
}
@media (max-width: 650px) {
  ul {
    display: none;
  }
  .section-block-intro {
    height: initial;
    padding-top: 3em;
  }
  .identity {
    flex-direction: column;
  }
  .identity-text {
    text-align: center;
    margin-left: 0px;
  }
  .parag {
    text-align: initial;
  }
}
@media (max-width: 600px) {
  .section-block-intro {
    background: $bkg-a;
  }
  .section-content-intro {
    width: 90%;
    height: initial;
    padding-top: 0em;
  }
}
</style>
