<template>
  <Section :sectionTitle="sectionTitle" :sectionId="sectionId" :ref="sectionId">
    <div class="section-left">
      <div class="skill-ctn">
        <h4 class="sub-title">Langages</h4>
        <ul class="skills-list">
          <li class="skill text-clear">JavaScript</li>
          <li class="skill text-clear">HTML</li>
          <li class="skill text-clear">CSS</li>
          <li class="skill text-clear">SCSS</li>
          <li class="skill text-clear">PHP</li>
          <li class="skill text-clear">MySQL</li>
        </ul>
      </div>
      <div class="skill-ctn">
        <h4 class="sub-title">Librairies & Frameworks</h4>
        <ul class="skills-list">
          <li class="skill text-clear">jQuery</li>
          <li class="skill text-clear">React</li>
          <li class="skill text-clear">Vue.js</li>
          <li class="skill text-clear">Laravel</li>
          <li class="skill text-clear">Bootstrap</li>
        </ul>
      </div>
      <div class="skill-ctn">
        <h4 class="sub-title">Tools & Platformes</h4>
        <ul class="skills-list">
          <li class="skill text-clear">Git / Github</li>
          <li class="skill text-clear">Jira / Confluence</li>
          <li class="skill text-clear">npm / yarn</li>
          <li class="skill text-clear">Axios</li>
          <li class="skill text-clear">Wordpress</li>
          <li class="skill text-clear">VS Code</li>
          <li class="skill text-clear">Trello</li>
        </ul>
      </div>
      <div class="skill-ctn">
        <h4 class="sub-title">Design / UI / UX</h4>
        <ul class="skills-list">
          <li class="skill text-clear">Adobe XD</li>
          <li class="skill text-clear">Figma</li>
          <li class="skill text-clear">Illustrator</li>
          <li class="skill text-clear">Photoshop</li>
          <li class="skill text-clear">InDesign</li>
        </ul>
      </div>
      <div class="skill-ctn">
        <h4 class="sub-title">Autres connaissances</h4>
        <ul class="skills-list">
          <li class="skill text-clear">Accessibilité web</li>
          <li class="skill text-clear">Normes W3C</li>
          <li class="skill text-clear">SEO</li>
          <li class="skill text-clear">Node.js</li>
          <li class="skill text-clear">TypeScript</li>
          <li class="skill text-clear">Mongo DB</li>
          <li class="skill text-clear">Webpack</li>
          <li class="skill text-clear">Taillwind</li>
          <li class="skill text-clear">GSAP</li>
          <li class="skill text-clear">Locomotive.js</li>
          <li class="skill text-clear">Swiper.js</li>
          <li class="skill text-clear">ISML</li>
          <li class="skill text-clear">SFCC-SFRA</li>
        </ul>
      </div>
    </div>
    <div class="section-right">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 100 100.7"
        style="enable-background: new 0 0 100 100.7"
        xml:space="preserve"
      >
        <g id="Calque_2"></g>
        <g id="Calque_1">
          <g>
            <path
              d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4
			c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1zM5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8l0,0c0.1-0.5,2.7-1.8,7.5-1.8s7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z"
            />
            <path
              d="M5268.4,2410.3c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.3c0.6,0,1-0.4,1-1s-0.4-1-1-1H5268.4z"
            />
            <path
              d="M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z"
            />
            <path
              d="M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z"
            />
          </g>
          <g>
            <path
              class="s-t0"
              d="M56.7,42.9l-9.5,10.3l-4.6-4.9c-1.1-1.2-3-1.3-4.2-0.2s-1.3,3-0.2,4.2l6.8,7.3c0.6,0.6,1.4,1,2.2,1
			s1.6-0.3,2.2-1L61,47c1.1-1.2,1.1-3.1-0.2-4.2C59.7,41.7,57.8,41.7,56.7,42.9z"
            />
            <path
              class="s-t0 rotating"
              d="M91.4,37.7h-4.7c-0.5-1.6-1.2-3.1-1.9-4.6l3.3-3.3c1.1-1.1,1.8-2.6,1.8-4.2s-0.6-3.1-1.8-4.2l-9.3-9.3
			c-2.3-2.3-6.1-2.3-8.5,0l-3.3,3.2c-1.5-0.7-3-1.4-4.6-1.9V8.8c0-3.3-2.7-6-6-6H43.4c-3.3,0-6,2.7-6,6v4.7
			c-1.6,0.5-3.1,1.2-4.6,1.9l-3.3-3.3c-2.3-2.3-6.2-2.3-8.5,0l-9.3,9.3c-2.3,2.3-2.3,6.1,0,8.5l3.3,3.3c-0.7,1.5-1.4,3-1.9,4.6H8.5
			c-3.3,0-6,2.7-6,6v13.1c0,3.3,2.7,6,6,6h4.7c0.5,1.6,1.2,3.1,1.9,4.6l-3.3,3.3c-2.3,2.3-2.3,6.1,0,8.5l9.3,9.3
			c2.3,2.3,6.1,2.3,8.5,0l3.3-3.3c1.5,0.7,3,1.4,4.6,1.9v4.7c0,3.3,2.7,6,6,6h13.1c3.3,0,6-2.7,6-6v-4.7c1.6-0.5,3.1-1.2,4.6-1.9
			l3.3,3.3c2.3,2.3,6.2,2.3,8.5,0l9.3-9.3c1.1-1.1,1.8-2.6,1.8-4.2s-0.6-3.1-1.8-4.2l-3.3-3.5c0.7-1.5,1.4-3,1.9-4.6h4.7
			c3.3,0,6-2.7,6-6V43.7C97.4,40.4,94.8,37.7,91.4,37.7z M91.4,56.9h-6.9c-1.3,0-2.5,0.9-2.9,2.2c-0.7,2.6-1.8,5.1-3.1,7.5
			c-0.7,1.2-0.5,2.6,0.5,3.6l4.9,4.9l-9.3,9.2l-4.8-4.8c-1-0.9-2.4-1.1-3.6-0.5c-2.3,1.3-4.8,2.3-7.5,3c-1.3,0.4-2.2,1.5-2.2,2.9
			v6.9H43.3v-6.9c0-1.3-0.9-2.5-2.2-2.9c-2.6-0.7-5.1-1.8-7.5-3.1c-0.5-0.3-1-0.4-1.5-0.4c-0.8,0-1.5,0.3-2.1,0.9l-4.9,4.9L16,75
			l4.8-4.8c1-0.9,1.1-2.4,0.5-3.6c-1.3-2.3-2.3-4.8-3-7.5c-0.4-1.3-1.5-2.2-2.9-2.2H8.5V43.7h6.9c1.3,0,2.5-0.9,2.9-2.2
			c0.7-2.6,1.8-5.1,3.1-7.5c0.7-1.2,0.5-2.6-0.5-3.6L16,25.5l9.3-9.3L30,21c0.9,0.9,2.4,1.1,3.6,0.5c2.3-1.3,4.8-2.4,7.4-3.1
			c1.3-0.4,2.2-1.5,2.2-2.9V8.6h13.2v6.9c0,1.3,0.9,2.5,2.2,2.9c2.6,0.7,5.2,1.8,7.5,3.1c1.2,0.7,2.6,0.5,3.6-0.5l4.9-4.9l9.3,9.3
			l-4.8,4.8c-0.9,0.9-1.1,2.4-0.5,3.6c1.3,2.3,2.4,4.8,3.1,7.4c0.4,1.3,1.5,2.2,2.9,2.2h6.9l0,0L91.4,56.9z"
            />
            <path
              class="s-t0"
              d="M50,25.7c-13.6,0-24.6,11-24.6,24.6s11,24.6,24.6,24.6s24.6-11,24.6-24.6S63.5,25.7,50,25.7z M50,68.9
			c-10.3,0-18.6-8.4-18.6-18.6c0-10.3,8.4-18.6,18.6-18.6S68.6,40,68.6,50.3S60.2,68.9,50,68.9z"
            />
          </g>
        </g>
      </svg>
    </div>
  </Section>
</template>

<script>
import Section from "./Section.vue";
export default {
  name: "Skills",
  components: {
    Section,
  },
  data() {
    return {
      sectionTitle: "Compétences",
      sectionId: "skills",
    };
  },
};
</script>

<style scoped lang="scss">
@import "../scss/variables.scss";
/* skills  */
.section-left {
  width: 70%;
}
.section-right {
  width: 20%;
}

.skill-ctn {
  width: 100%;
  margin-bottom: 2rem;
}

.sub-title {
  color: $green;
  font-weight: $light;
  font-size: $h4;
}
.skills-list {
  display: flex;
  justify-content: flex-start;
  padding-left: 0px;
  flex-wrap: wrap;
}

.skill {
  list-style-type: none;
  padding: 3px 10px;
  color: $text;
  border-left: 1px solid $bkg-b;
}

.skill-ctn .skill {
  text-align: center;
  margin-bottom: 0.5rem;
}
.s-t0 {
  fill: $transparent;
  stroke: $bkg-a;
  stroke-width: 3px;
}

@keyframes rotating {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotating 50s linear infinite;
  transform-origin: center;
}
@media (max-width: 1200px) {
  .section-right {
    display: none;
  }
  .section-left {
    width: 100%;
  }
}
</style>
