<template>
  <section class="section-block section" :id="sectionId" :ref="sectionId">
    <div class="section-title">
      <h3 class="title">{{ sectionTitle }}</h3>
      <div class="title-line"></div>
    </div>
    <div class="section-content">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: "Section",
  props: {
    sectionTitle: {
      type: String,
      default: "Title",
    },
    sectionId: {
      type: String,
    },
    ref: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "../scss/variables.scss";
.section-block {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 30em;
  padding-top: 7em;
}

.section-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4em;
}

.title {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: fit-content;
  margin-right: 20px;
  transition: all 0.3s ease-out;
  color: $light;
}

.title-line {
  content: "";
  width: 100%;
  height: 1px;
  left: 62px;
  background-color: $bkg-b;
  display: none;
}

.section-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}
@media (max-width: 910px) {
  .project {
    width: 80%;
  }
  .section-content {
    justify-content: center;
  }
}
@media (max-width: 600px) {
  .section-block {
    width: 90%;
  }
  h3.title {
    font-size: 1.8em;
  }
}
</style>
