<template>
  <div class="loader-ctn">
    <div class="loader">
      <svg
        version="1.1"
        id="logo-loader"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 352.5 123.3"
        style="enable-background: new 0 0 352.5 123.3"
        xml:space="preserve"
      >
        <polygon class="loader-1" points="123.8,16.6 71.8,106.7 175.9,106.7 " />
        <polygon class="loader-2" points="228.6,16.6 176.6,106.7 280.7,106.7 " />
      </svg>
    </div>
  </div>
  <Nav />
  <main>
    <Intro />
    <Skills />
    <!-- <Experience />@ -->
    <Projects />
    <!-- <Diplomas /> -->
    <Footer />
    <button v-on:click="topFunction" id="myBtn" ref="myBtn" title="Go to top">
      <img src="../src/assets/SVG/top.svg" />
    </button>
  </main>
  <div class="progress-container">
    <div class="progress-bar" id="myBar"></div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import TextPlugin from "gsap/TextPlugin";
import Nav from "./components/Nav.vue";
import Intro from "./components/Intro.vue";
import Skills from "./components/Skills.vue";
// import Experience from "./components/Experience.vue";
// import Diplomas from "./components/Diplomas.vue";
import Projects from "./components/Projects.vue";
import Footer from "./components/Footer.vue";

gsap.registerPlugin(TextPlugin);

export default {
  name: "App",
  components: {
    Nav,
    Intro,
    Skills,
    // Experience,
    // Diplomas,
    Projects,
    Footer,
  },
  mounted() {
    const words = ["Développeur web", "Intégrateur web", "UI designer"];

    gsap.to(".cursor", {
      opacity: 0,
      ease: "power2.inOut",
      repeat: -1,
      repeatDelay: 0.5,
    });
    let masterTl = gsap
      .timeline({
        repeat: -1,
      })
      .pause();

    // Loader
    var tLoad1 = gsap.timeline({
      repeat: 1,
      repeatDelay: 1,
      delay: 0.6,
    });
    var tLoad2 = gsap.timeline({
      repeat: 1,
      repeatDelay: 1,
      delay: 0.6,
    });

    tLoad1
      .to(".loader-1", {
        duration: 1,
        x: 104.5,
        opacity: 1,
      })
      .to(".loader-1", {
        duration: 2,
        x: 104.5,
        opacity: 0,
      });
    tLoad2
      .to(".loader-2", {
        duration: 1,
        x: -104.5,
        opacity: 1,
      })
      .to(".loader-2", {
        duration: 2,
        x: -104.5,
        opacity: 0,
      });
    // Intro anim
    var animation = gsap.timeline({});

    animation
      .to(".loader-ctn", {
        y: -2000,
        scale: 1,
        duration: 1,
        ease: "power2.inOut",
        delay: 2,
        opacity: 0,
      })
      .set("body", {
        overflow: "initial",
      })
      .from("#header", {
        y: -10,
        opacity: 0,
        scale: 1,
        duration: 0.4,
        ease: "power2.inOut",
        delay: 0.2,
        stagger: 0.2,
      })
      .from(".intro", {
        y: -10,
        opacity: 0,
        scale: 1,
        duration: 0.2,
        ease: "expo.out",
        delay: 0.1,
        stagger: 0.1,
        onComplete: function () {
          masterTl.play();
          words.forEach((word) => {
            let tl = gsap.timeline({
              repeat: 1,
              yoyo: true,
              repeatDelay: 2,
            });
            tl.to(".text", {
              duration: 2.5,
              text: word,
            });
            masterTl.add(tl);
          });
        },
      })
      .from(".social-icon", {
        x: 40,
        opacity: 0,
        scale: 1,
        duration: 0.3,
        ease: "power2.inOut",
        delay: 0.1,
        stagger: 0.1,
        onComplete: function () {
          var tl = gsap.timeline({
            repeat: -1,
            repeatDelay: 3,
          });
          var tl1 = gsap.timeline({
            repeat: -1,
            repeatDelay: 3,
          });

          tl.to(".st0", {
            duration: 1,
            x: 104.5,
          });
          tl1.to(".st1", {
            duration: 1,
            x: -104.5,
          });
        },
      });

    // active link on scroll
    const sections = document.querySelectorAll(".section");
    const navLink = document.querySelectorAll("nav .menu-ctn ul.menu li a");

    // Btn top
    const myBtn = this.$refs["myBtn"];
    function scrollFunction(myBtn) {
      if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
        myBtn.style.display = "block";
      } else {
        myBtn.style.display = "none";
      }
    }
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
      scrollFunction(myBtn);
      let currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById("header").style.top = "0";
        // document.getElementById("social").style.bottom = "0";
        document.getElementById("social").style.opacity = "1";
        // document.getElementById("scroll-down").style.bottom = "45px";
      } else {
        document.getElementById("header").style.top = "-65px";
        // document.getElementById("social").style.bottom = "-300px";
        document.getElementById("social").style.opacity = "0";
        // document.getElementById("scroll-down").style.bottom = "-200px";
      }
      prevScrollpos = currentScrollPos;

      indicateOnScroll();
      // Scroll indicator
      function indicateOnScroll() {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const height =
          document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrolled = (winScroll / height) * 100;
        document.getElementById("myBar").style.width = scrolled + "%";
      }
      // link active
      var current = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (pageYOffset >= sectionTop - 400) {
          current = section.getAttribute("id");
        }
      });

      navLink.forEach((a) => {
        a.classList.remove("active");
        if (a.classList.contains(current)) {
          a.classList.add("active");
        }
      });
    };
  },
  methods: {
    topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "./scss/variables.scss";

/* Loader  */
.loader-ctn {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $bkg-b;
  z-index: 10000000;
  overflow: hidden;
}

.loader {
  width: 100px;
}
.loader-1,
.loader-2 {
  fill: none;
  stroke: $green;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  opacity: 0;
}
* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

*::selection {
  background-color: $transparent;
}

html,
body {
  background-color: $bkg-a;
  padding: 0;
  margin: 0;
  width: 100%;
  scroll-behavior: smooth !important;
}

.body {
  overflow: hidden;
}

h1,
h2,
h3,
h4 {
  margin: 0px;
}

a {
  text-decoration: none;
  font-weight: $light;
  font-size: $small;
}

main {
  width: 100%;
  z-index: 2;
}

h1 {
  color: $clear;
  font-weight: $bold;
  font-size: $h1;
  margin-bottom: 10px;
}

h2 {
  color: $text;
  font-weight: $light;
  font-size: $h2;
}

h3 {
  color: $clear;
  font-weight: $semi-bold;
  font-size: $h3;
}
.sub-title-clear {
  color: $clear;
  font-weight: $semi-bold;
  font-size: $h4;
}
%font-style-1 {
  font-weight: $light;
  font-size: $small;
}

.text-green {
  color: $green;
  margin-bottom: 10px;
  @extend %font-style-1;
}

.text-clear {
  color: $clear;
  line-height: 22px;
  @extend %font-style-1;
}

.text-dark {
  color: $text;
  @extend %font-style-1;
}

.text-dark-big {
  color: $text;
  line-height: 25px;
  @extend %font-style-1;
}
.progress-container {
  width: 100%;
  height: 1px;
  background: $bkg-a;
  position: fixed;
  top: 0;
  z-index: 100;
}
.progress-bar {
  height: 1px;
  background: -webkit-linear-gradient(left, #217981, #00e3ff);
  // background-clip: border-box;
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  width: 0%;
}
#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  left: 30px;
  z-index: 99;
  color: $green;
  border: 1px solid $green;
  border-radius: $border-radius;
  padding: 0.7em 0.5em;
  font-size: $small;
  cursor: pointer;
  transition: all 0.35s;
  line-height: 24px;
  width: 37px;
  background-color: transparent;
  &:hover {
    background-color: $transparent;
  }
  &:active {
    border: 1px dotted $green;
  }
}
@media (max-width: 720px) {
  #myBtn {
    visibility: hidden;
  }
}
@media (max-width: 966px) {
  #experiences {
    display: none;
  }
}
</style>
