<template>
  <header id="header">
    <nav>
      <div id="logo">
        <span class="logo-link">
          <svg
            version="1.1"
            id="logo-svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 352.5 123.3"
            style="enable-background: new 0 0 352.5 123.3"
            xml:space="preserve"
          >
            <polygon class="st0" points="123.8,16.6 71.8,106.7 175.9,106.7 " />
            <polygon class="st1" points="228.6,16.6 176.6,106.7 280.7,106.7 " />
          </svg>
        </span>
      </div>
      <div class="menu-ctn">
        <ul class="menu">
          <li class="menu-item">
            <a class="skills" href="#skills">Compétences</a>
          </li>
          <!-- <li class="menu-item" id="experiences-link">
            <a class="experiences" href="#experiences"> Expériences </a>
          </li> -->
          <li class="menu-item">
            <a class="projects" href="#projects">Projets</a>
          </li>
          <!-- <li class="menu-item">
            <a class="diplomas" href="#diplomas">Études</a>
          </li> -->
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Nav",
  emits: ["test"],
};
</script>

<style scoped lang="scss">
@import "../scss/variables.scss";
header {
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  width: 100%;
  transition: top 0.3s;
  z-index: 24;
  height: 60px;
  background-color: #101520e3;
}

/* Navigation  */
nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  height: 60px;
  text-align: center;
  padding: 0 20px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.26) 0px 2px 8px 0px;
  box-shadow: rgba(0, 0, 0, 0.26) 0px 2px 8px 0px;
}

ul {
  display: flex;
  justify-content: space-around;
}

li {
  list-style-type: none;
  padding: 10px;
}

li {
  a {
    color: $text;
    text-decoration: none;
    font-weight: $light;
    font-size: $small;
    position: relative;
    padding-bottom: 5px;
    transition: all 0.35s;
    &:hover {
      color: $green;
    }
    &:active {
      border-bottom: dotted 1px $green;
    }
  }
}
#logo {
  width: 80px;
  display: inline-block;
  opacity: 1;
}

.st0,
.st1 {
  fill: none;
  stroke: $green;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.menu-item a.active {
  border-bottom: solid 1px $green;
}
@media (max-width: 600px) {
  #header {
    display: none;
  }
}
@media (max-width: 966px) {
  #experiences-link {
    display: none;
  }
}
</style>
