<template>
  <Section :sectionTitle="sectionTitle" :sectionId="sectionId" ref="projects">
    <div class="project">
      <div class="project-header">
        <div class="project-date">10 / 2021</div>
        <div class="project-links">
          <a
            class="link-icon"
            href="https://pad.philharmoniedeparis.fr/colloque-engagement-social-et-culturel-du-musicien.aspx"
            title="Visiter le site"
            target="blank"
          ></a>
        </div>
      </div>
      <div class="project-title">
        <h4 class="sub-title">
          <a
            href="https://pad.philharmoniedeparis.fr/colloque-engagement-social-et-culturel-du-musicien.aspx"
            target="blank"
            >PPLiveChat</a
          >
        </h4>
      </div>
      <div class="project-description">
        <p>
          Conception et intégration d'une application de chat pour la Philharmonie de
          Paris (en collaboration avec les chefs de projet et le développeur Back-end).
        </p>
      </div>
      <div class="project-tech">
        <ul class="skills-list">
          <li class="skill-project">JavaScript</li>
          <li class="skill-project">jQuery</li>
          <li class="skill-project">HTML</li>
          <li class="skill-project">CSS</li>
          <li class="skill-project">Vue.js</li>
          <li class="skill-project">Illustrator</li>
          <li class="skill-project">Adobe XD</li>
        </ul>
      </div>
    </div>
    <div class="project">
      <div class="project-header">
        <div class="project-date">09 / 2021</div>
        <div class="project-links">
          <a
            class="link-icon-git"
            target="blank"
            href="https://github.com/MKM84/bitchest"
            title="Voir sur Github"
          ></a>
        </div>
      </div>
      <div class="project-title">
        <h4 class="sub-title">
          <a href="https://github.com/MKM84/bitchest" target="blank">BitChest</a>
        </h4>
      </div>
      <div class="project-description">
        <p>
          Une application web destinée à permettre aux particuliers d'acheter et de vendre
          des cryptomonnaies, telles que le Bitcoin ou l'Ethereum.
        </p>
      </div>
      <div class="project-tech">
        <ul class="skills-list">
          <li class="skill-project green">Projet d'école</li>
          <li class="skill-project">Vue.js</li>
          <li class="skill-project">Laravel</li>
          <li class="skill-project">Axios</li>
          <li class="skill-project">Sanctum API</li>
          <li class="skill-project">Chart.js</li>
          <li class="skill-project">Bootstrap</li>
          <li class="skill-project">REST API</li>
        </ul>
      </div>
    </div>
    <div class="project">
      <div class="project-header">
        <div class="project-date">07 / 2021</div>
        <div class="project-links">
          <a
            class="link-icon"
            href="https://pad.philharmoniedeparis.fr/home.aspx"
            title="Visiter le site"
            target="blank"
          ></a>
        </div>
      </div>
      <div class="project-title">
        <h4 class="sub-title">
          <a href="https://pad.philharmoniedeparis.fr/home.aspx" target="blank"
            >Philhramonie à la Demande</a
          >
        </h4>
      </div>
      <div class="project-description">
        <p>
          Développement de nouvelles fonctionnalités et intégration de pages web en se
          concentrant sur l'accessibilité et les tests de compatibilité entre les
          différents navigateurs...
        </p>
      </div>
      <div class="project-tech">
        <ul class="skills-list">
          <li class="skill-project">JavaScript</li>
          <li class="skill-project">jQuery</li>
          <li class="skill-project">HTML</li>
          <li class="skill-project">CSS</li>
          <li class="skill-project">XML</li>
          <li class="skill-project">XSLT</li>
          <li class="skill-project">W3C</li>
          <li class="skill-project">API</li>
          <li class="skill-project">API</li>
        </ul>
      </div>
    </div>
    <div class="project">
      <div class="project-header">
        <div class="project-date">06 / 2021</div>
        <div class="project-links">
          <a
            class="link-icon-git"
            href="https://github.com/MKM84/WeFashion"
            target="blank"
            title="Voir sur Github"
          ></a>
        </div>
      </div>
      <div class="project-title">
        <h4 class="sub-title">
          <a href="https://github.com/MKM84/WeFashion" target="blank">WeFashion</a>
        </h4>
      </div>
      <div class="project-description">
        <p>
          Site d'e-commerce réalisé pendant mes études. Le site répond aux exigences du
          cahier des charges, mais il reste potentiellement améliorable.
        </p>
      </div>
      <div class="project-tech">
        <ul class="skills-list">
          <li class="skill-project green">Projet d'école</li>
          <li class="skill-project">PHP</li>
          <li class="skill-project">Laravel</li>
          <li class="skill-project">Bootstrap</li>
          <li class="skill-project">Blade</li>
        </ul>
      </div>
    </div>
    <div class="project">
      <div class="project-header">
        <div class="project-date">06 / 2021</div>
        <div class="project-links">
          <a
            class="link-icon"
            href="https://edutheque.philharmoniedeparis.fr/"
            title="Visiter le site"
            target="blank"
          ></a>
        </div>
      </div>
      <div class="project-title">
        <h4 class="sub-title">
          <a href="https://edutheque.philharmoniedeparis.fr/" target="blank">Eduthèque</a>
        </h4>
      </div>
      <div class="project-description">
        <p>
          Intégration de pages web, contribution au maintien et à l'amélioration continue
          du site, gestion des bugs...
        </p>
      </div>
      <div class="project-tech">
        <ul class="skills-list">
          <li class="skill-project">HTML</li>
          <li class="skill-project">CSS</li>
          <li class="skill-project">XML</li>
          <li class="skill-project">XSLT</li>
          <li class="skill-project">W3C</li>
          <li class="skill-project">SVG</li>
        </ul>
      </div>
    </div>
    <div class="project">
      <div class="project-header">
        <div class="project-date">03 / 2021</div>
        <div class="project-links">
          <a
            class="link-icon"
            href="https://mkm84.github.io/KwickChatApp/"
            target="blank"
            title="Voir la demo"
          ></a>
          <a
            class="link-icon-git"
            href="https://github.com/MKM84/KwickChatApp"
            target="blank"
            title="Voir sur Github"
          ></a>
        </div>
      </div>
      <div class="project-title">
        <h4 class="sub-title">
          <a href="https://mkm84.github.io/KwickChatApp/" target="blank">KwickMsg</a>
        </h4>
      </div>
      <div class="project-description">
        <p>
          Une messagerie instantanée simplifiée permettant à un groupe de personnes
          d'échanger des messages depuis un navigateur web.
        </p>
      </div>
      <div class="project-tech">
        <ul class="skills-list">
          <li class="skill-project green">Projet d'école</li>
          <li class="skill-project">JavaScript</li>
          <li class="skill-project">JQuery</li>
          <li class="skill-project">HTML</li>
          <li class="skill-project">CSS</li>
          <li class="skill-project">API</li>
        </ul>
      </div>
    </div>

    <div v-if="showed == true" class="project">
      <div class="project-header">
        <div class="project-date">09 / 2020</div>
        <div class="project-links">
          <a
            class="link-icon-git"
            href="https://github.com/MKM84/MoviesBoardReact"
            target="blank"
            title="Voir sur Github"
          ></a>
        </div>
      </div>
      <div class="project-title">
        <h4 class="sub-title">
          <a href="https://github.com/MKM84/MoviesBoardReact" target="blank"
            >MoviesBoard</a
          >
        </h4>
      </div>
      <div class="project-description">
        <p>
          Une application de gestion d’une bibliothèque de films. L'application communique
          avec un serveur web REST.
        </p>
      </div>
      <div class="project-tech">
        <ul class="skills-list">
          <li class="skill-project green">Projet d'école</li>
          <li class="skill-project">React</li>
          <li class="skill-project">JavaScript</li>
          <li class="skill-project">HTML</li>
          <li class="skill-project">CSS</li>
          <li class="skill-project">TMDB API</li>
        </ul>
      </div>
    </div>
    <div v-if="showed == true" class="project">
      <div class="project-header">
        <div class="project-date">06 / 2020</div>
        <div class="project-links">
          <a
            class="link-icon-git"
            target="blank"
            href="https://github.com/MKM84/nodeBlog"
          ></a>
        </div>
      </div>
      <div class="project-title">
        <h4 class="sub-title">
          <a href="https://github.com/MKM84/nodeBlog" target="blank">BlogWithNode</a>
        </h4>
      </div>
      <div class="project-description">
        <p>
          Le but de ce projet est de créer un petit système de blog en JavaScript et
          Node.js...
        </p>
      </div>
      <div class="project-tech">
        <ul class="skills-list">
          <li class="skill-project">JavaScript</li>
          <li class="skill-project green">Projet d'école</li>
          <li class="skill-project">Pug</li>
          <li class="skill-project">Node.js</li>
          <li class="skill-project">Express</li>
          <li class="skill-project">MongoDB Atlas</li>
        </ul>
      </div>
    </div>
    <div v-if="showed == true" class="project">
      <div class="project-header">
        <div class="project-date">04 / 2020</div>
        <div class="project-links">
          <a
            class="link-icon"
            href="FLYER_PAD.pdf"
            title="Visiter le site"
            target="blank"
          ></a>
        </div>
      </div>
      <div class="project-title">
        <h4 class="sub-title">
          <a href="/FLYER_PAD.pdf" target="blank">Flyer PAD</a>
        </h4>
      </div>
      <div class="project-description">
        <p>
          À partir du brief de la cheffe de projet, conception et réalisation d'un flyer
          pour promouvoir le site Philharmonie à la demande.
        </p>
      </div>
      <div class="project-tech">
        <ul class="skills-list">
          <li class="skill-project">Indesign</li>
          <li class="skill-project">Photoshop</li>
          <li class="skill-project">Illustrator</li>
        </ul>
      </div>
    </div>
    <div v-if="showed == true" class="project">
      <div class="project-header">
        <div class="project-date">05 / 2020</div>
        <div class="project-links">
          <a
            class="link-icon-git"
            target="blank"
            href="https://github.com/MKM84/DeezWeb"
          ></a>
        </div>
      </div>
      <div class="project-title">
        <h4 class="sub-title">
          <a href="https://github.com/MKM84/DeezWeb" target="blank">DeezWeb</a>
        </h4>
      </div>
      <div class="project-description">
        <p>
          Une application web permettant de rechercher, explorer et organiser de la
          musique.
        </p>
      </div>
      <div class="project-tech">
        <ul class="skills-list">
          <li class="skill-project green">Projet d'école</li>
          <li class="skill-project">jQuery</li>
          <li class="skill-project">HTML</li>
          <li class="skill-project">CSS</li>
          <li class="skill-project">Deezer API</li>
        </ul>
      </div>
    </div>
    <div v-if="showed == true" class="project">
      <div class="project-header">
        <div class="project-date">2018</div>
        <div class="project-links">
          <a class="link-icon" href="/NOUCO.pdf" target="blank"></a>
        </div>
      </div>
      <div class="project-title">
        <h4 class="sub-title">
          <a href="/NOUCO.pdf" target="blank">NOUCO</a>
        </h4>
      </div>
      <div class="project-description">
        <p>
          Conception d'une application représentant une conciergerie collective
          d'entreprise facilitant l'échange, le partage et l'adhésion à des activités.
        </p>
      </div>
      <div class="project-tech">
        <ul class="skills-list">
          <li class="skill-project green">Projet d'école</li>
          <li class="skill-project">UI</li>
          <li class="skill-project">UX</li>
          <li class="skill-project">DA</li>
          <li class="skill-project">Adobe XD</li>
          <li class="skill-project">Illustrator</li>
          <li class="skill-project">Photoshop</li>
        </ul>
      </div>
    </div>
    <div v-if="showed == true" class="project">
      <div class="project-header">
        <div class="project-date">2017</div>
        <div class="project-links">
          <a class="link-icon" href="/POLETTE.pdf" target="blank"></a>
        </div>
      </div>
      <div class="project-title">
        <h4 class="sub-title">
          <a href="/POLETTE.pdf" target="blank">Polette</a>
        </h4>
      </div>
      <div class="project-description">
        <p>
          Dans le cadre d'un exercice pratique lors de ma formation, j'ai effectué une
          refonte de la page produit ainsi que du tunnel d'achat du site Polette.
        </p>
      </div>
      <div class="project-tech">
        <ul class="skills-list">
          <li class="skill-project green">Projet d'école</li>
          <li class="skill-project">UI</li>
          <li class="skill-project">UX</li>
          <li class="skill-project">DA</li>
          <li class="skill-project">Adobe XD</li>
          <li class="skill-project">Illustrator</li>
          <li class="skill-project">Photoshop</li>
        </ul>
      </div>
    </div>
    <div v-if="showed == true" class="project">
      <div class="project-header">
        <div class="project-date">2016</div>
        <div class="project-links">
          <a
            class="link-icon"
            href="https://www.fehap.fr/upload/docs/application/pdf/2018-03/rapport-dactivite-2015.pdf"
            target="blank"
          ></a>
        </div>
      </div>
      <div class="project-title">
        <h4 class="sub-title">
          <a href="/rap-acti-print.pdf" target="blank">Rapport d'activité</a>
        </h4>
      </div>
      <div class="project-description">
        <p>
          Réalisation et mise en page du rapport d'activité en collaboration avec l'équipe
          de communication de la FEHAP.
        </p>
      </div>
      <div class="project-tech">
        <ul class="skills-list">
          <li class="skill-project">Indesign</li>
          <li class="skill-project">Illustrator</li>
          <li class="skill-project">Photoshop</li>
        </ul>
      </div>
    </div>

    <div class="btn-component-ctn">
      <Btn :isBtn="true" v-on:click="showMoreProject"> {{ btnText }}</Btn>
    </div>
  </Section>
</template>

<script>
import Section from "./Section.vue";
import Btn from "./Btn.vue";
export default {
  name: "Projects",
  components: {
    Section,
    Btn,
  },
  data() {
    return {
      sectionTitle: "Sélection de projets",
      sectionId: "projects",
      showed: false,
      btnText: "Afficher plus",
    };
  },
  methods: {
    showMoreProject() {
      this.showed = !this.showed;
      if (this.showed) {
        this.btnText = "Afficher moins";
      } else {
        this.btnText = "Afficher plus";
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../scss/variables.scss";
.hidden {
  display: none;
}
.projects {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.project {
  width: 32%;
  display: flex;
  flex-direction: column;
  height: 385px;
  background-color: $bkg-c;
  border-radius: 10px;
  transition: all 0.3s;
  position: relative;
  margin: 6px;
  border: solid 1px $bkg-c;
}

.hidden-project {
  visibility: unset;
  opacity: 0;
  display: none;
}

.show-more-projects {
  margin: 0 auto;
  margin-top: -23em;
  margin-bottom: 24em;
}

.project:hover {
  transform: translate(0, -4px);
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.378));
  border: solid 1px $green;
}
.project:active {
  border: dotted 1px $green;
}

.project-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  color: $text;
  font-size: $x-small;
  padding: 22px 16px 18px 16px;
  z-index: 20;
}

.project-date {
  color: $green;
}

.link-icon {
  background: url("../assets/SVG/external_link.svg") no-repeat scroll center center;
  width: 42px;
  height: 42px;
  display: inline-block;
  background-size: 22px;
  // cursor: ne-resize;
  background-color: $bkg-a;
  border-radius: 5px;
}

.link-icon:hover {
  background: url("../assets/SVG/external_link_hover.svg") no-repeat scroll center center;
  width: 42px;
  height: 42px;
  background-size: 22px;
  background-color: $transparent;
  border-radius: 5px;
}

.link-icon-git {
  background: url("../assets/SVG/picto_github.svg") no-repeat scroll center center;
  width: 42px;
  height: 42px;
  display: inline-block;
  background-size: 22px;
  // cursor: ne-resize;
  background-color: $bkg-a;
  border-radius: 5px;
  margin-left: 7px;
}

.link-icon-git:hover {
  background: url("../assets/SVG/picto_github_hover.svg") no-repeat scroll center center;
  width: 42px;
  height: 42px;
  display: inline-block;
  background-size: 22px;
  // cursor: ne-resize;
  background-color: $transparent;
  border-radius: 5px;
}

.project-title {
  height: 50px;
  padding: 16px;
  display: flex;
  align-items: center;
}
.sub-title {
  color: $green;
  font-weight: $light;
  font-size: $h4;
  transition: all 0.35s;
  a {
    color: $clear;
    font-weight: $semi-bold;
    font-size: $h4;
    // cursor: ne-resize;
    position: static;
    transition: all 0.35s;
  }
  & a::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
  }
  & a:active {
    border-bottom: 1px dotted $green;
  }
  & a:hover {
    color: $green;
  }
}

.project-description {
  padding: 0px 16px 16px 16px;
  color: $text;
  height: 160px;
}

.project-description p {
  font-size: $small;
  font-weight: $light;
  margin: 0px;
  line-height: 2;
}

.project-tech {
  padding: 0 16px;
}

.project-tech .skill {
  background-color: $bkg-a;
  padding: 3px 6px;
  margin-bottom: 8px;
  margin-right: 8px;
  border-radius: 3px;
  border: none;
}

.project-links .link-icon:active {
  border: dotted 1px $green;
}

.project-links .link-icon-git:active {
  border: dotted 1px $green;
}
.skills-list {
  display: flex;
  justify-content: flex-start;
  padding-left: 0px;
  flex-wrap: wrap;
}

.skill-project {
  list-style-type: none;
  color: $text;
  font-weight: $light;
  font-size: $x-small;
  background-color: $bkg-a;
  padding: 3px 6px;
  margin-bottom: 8px;
  margin-right: 8px;
  border-radius: 3px;
  border: none;
}
.skill-project.green {
  color: $green;
}
.btn-component-ctn {
  margin: 0 auto;
  width: 100%;
  align-self: flex-end;
  text-align: center;
}
@media (max-width: 1300px) {
  .project {
    width: 45%;
  }
}
@media (max-width: 950px) {
  .project {
    width: 90%;
    height: 330px;
  }
}
@media (max-width: 600px) {
  .project {
    width: 100%;
    height: 385px;
  }
}
</style>
