<template>
  <div :class="btnLeft == true ? 'btn-ctn-left' : 'btn-ctn'">
    <button v-if="isBtn" class="btn show-more"><slot></slot></button>
    <a v-else class="btn" :href="link" target="_blank"> <slot></slot></a>
  </div>
</template>

<script>
export default {
  name: "Btn",
  props: {
    link: {
      type: String,
    },
    btnLeft: {
      type: Boolean,
    },
    isBtn: {
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
@import "../scss/variables.scss";
/* btn  */
.btn-ctn {
  margin: 0 auto;
  margin-top: 42px;
  transition: all 0.35s;
  max-width: max-content;
}
.btn-ctn-left {
  transition: all 0.35s;
  max-width: max-content;
}
.btn {
  color: $green;
  border: 1px solid $green;
  border-radius: $border-radius;
  padding: 0.6em 1em;
  font-size: $small;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: all 0.35s;
  line-height: 24px;
  background-color: transparent;
  &:hover {
    background-color: $transparent;
  }
  &:active {
    border: 1px dotted $green;
  }
}
</style>
