<template>
  <footer class="section">
    <div class="footer-content">
      <h3 class="title-footer">Restons en contact</h3>
      <p class="text-dark-big text-contact">
        Si vous avez des questions ou vous souhaitez me parler de vos projets,
        n'hésitez pas de me contacter, je serai à votre écoute !
      </p>
      <div class="social-media-ctn-mobile">
        <ul class="social-media-mobile">
          <li class="social-icon-mobile">
            <a
              href="https://github.com/MKM84"
              id="git-mobile"
              target="blank"
            ></a>
          </li>
          <li class="social-icon-mobile">
            <a
              href="https://www.linkedin.com/in/majedkhreim/"
              id="linkedin-mobile"
              target="blank"
            ></a>
          </li>
          <!-- <li class="social-icon"><a href="#" id="codepen"></a></li>
        <li class="social-icon"><a href="#" id="behance"></a></li> -->
        </ul>
      </div>
      <Btn :link="link" class="shake"> Me contacter </Btn>
    </div>
    <!-- <div id="scroll-down">
      <div class="line"></div>
      <p class="text-clear">Scrollez</p>
    </div> -->
    <div class="social-media-ctn" id="social">
      <ul class="social-media">
        <li class="social-icon">
          <a href="https://github.com/MKM84" id="git" target="blank"></a>
        </li>
        <li class="social-icon">
          <a
            href="https://www.linkedin.com/in/majedkhreim/"
            id="linkedin"
            target="blank"
          ></a>
        </li>
        <!-- <li class="social-icon"><a href="#" id="codepen"></a></li>
        <li class="social-icon"><a href="#" id="behance"></a></li> -->
      </ul>
    </div>

    <p class="text-dark-big copy-right">© Majed Khreim</p>
  </footer>
</template>

<script>
import Btn from "./Btn.vue";

export default {
  name: "Projects",
  components: {
    Btn,
  },
  data() {
    return {
      link: "https://www.linkedin.com/in/majedkhreim/",
    };
  },
};
</script>

<style scoped lang="scss">
@import "../scss/variables.scss";

footer {
  text-align: center;
  padding-bottom: 1em;
  margin: 0 auto;
  width: 100%;
  background: rgb(16, 21, 32);
  background: linear-gradient(
    180deg,
    rgba(16, 21, 32, 0.4822303922) 21%,
    #097487 852%,
    rgba(0, 227, 255, 0.4822303922) 100%
  );
  position: relative;
}
.footer-content {
  width: fit-content;
  max-width: 60%;
  margin: 0 auto;
  margin-bottom: 12em;
  border: 5px solid $transparent;
  padding: 3em;
  border-radius: 1em;
}

.copy-right {
  // border-top: 1px solid $bkg-b;
  width: 40%;
  margin: 0 auto;
  padding-top: 18px;
}

.title-footer {
  margin: 0 auto;
  margin-bottom: 1em;
  background: -webkit-linear-gradient(left, #217981, #00e3ff);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-contact {
  margin-top: 0px;
  margin-bottom: 0px;
}
.text-contact span {
  font-size: 25px;
}
/* social medias section */
#social {
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: 1;
  transition: all 0.1s ease-in-out;
}

ul.social-media {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  opacity: 1;
  padding: 0px;
}
.social-media-ctn-mobile {
  margin-bottom: -1em;
}
ul.social-media-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  opacity: 1;
  padding: 0px;
}

li.social-icon,
li.social-icon-mobile {
  list-style-type: none;
  padding: 10px 10px;
  border: solid 1px transparent;
}

li.social-icon a,
li.social-icon-mobile a {
  text-decoration: none;
  width: 25px;
  height: 22px;
  display: inline-block;
  // cursor: ne-resize;
  outline: none;
}

li.social-icon:active,
li.social-icon-mobile:active {
  border: dotted 1px $green;
}

a#git,
a#git-mobile {
  background: url("../../src/assets/SVG/picto_github.svg") no-repeat scroll
    center center;
}

a#linkedin,
a#linkedin-mobile {
  background: url("../../src/assets/SVG/picto_linkedin.svg") no-repeat scroll
    center center;
}

a#codepen {
  background: url("../../src/assets/SVG/picto_codepen.svg") no-repeat scroll
    center center;
}

a#behance {
  background: url("../../src/assets/SVG/picto_behance.svg") no-repeat scroll
    center center;
}

/* hover  */
a#git:hover,
a#git-mobile:hover {
  background: url("../../src/assets/SVG/picto_github_hover.svg") no-repeat
    scroll center center;
}

a#linkedin:hover,
a#linkedin-mobile:hover {
  background: url("../../src/assets/SVG/picto_linkedin_hover.svg") no-repeat
    scroll center center;
}

a#codepen:hover {
  background: url("../../src/assets/SVG/picto_codepen_hover.svg") no-repeat
    scroll center center;
}

a#behance:hover {
  background: url("../../src/assets/SVG/picto_behance_hover.svg") no-repeat
    scroll center center;
}
#scroll-down {
  transform: rotate(-90deg);
  position: fixed;
  bottom: 45px;
  left: 10px;
  z-index: 1;
  transition: bottom 0.3s;
}

#scroll-down p {
  margin: 0px;
  color: $text;
  font-weight: $light;
  font-size: $small;
  position: relative;
}

#scroll-down .line {
  content: "";
  position: absolute;
  top: 10px;
  width: 107px;
  height: 1px;
  left: 62px;
  background-color: $text;
}
.shake:hover {
  animation: shake 1.5s ease-in-out both infinite;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
@media (max-width: 600px) {
  .footer-content {
    width: 90%;
  }
  .social-media-ctn {
    visibility: hidden;
  }
  h3.title-footer {
    font-size: 1.5em;
  }
}
@media (min-width: 600px) {
  .social-media-ctn-mobile {
    display: none;
  }
}
</style>
